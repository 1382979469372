import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://www.youtube.com/embed/YrJTHc7u0Bg"
    bibleGroupSrc="https://www.docdroid.net/BW76gZB/bible-group-homework-9-20-pdf"
    bibleGroupText="Bible Group Homework"
    notesText="Sermon Notes"
    notesSrc="https://www.bible.com/events/40884244"
  >
    <SEO title="Together for Families - Together is Better" />
  </Layout>
)

export default SermonPost
